/* header and navbar */
header {
  background-color: white;
  width: 100%;
  z-index: 100000;
}

.navbar {
  margin-bottom: 0;
  min-height: 70px;
}

.navbar-brand {
  padding: 15px 15px 15px 0;
}

.navbar-brand img {
  max-width: 200px;
}

.navbar-nav > li > a {
  padding-top: 20px;
  color: #00A1D0;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.navbar-toggle {
  background-color: #00A1D0;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/*cookie-alert*/
section.cookie-alert {
  background-color: #f2f2f2;
}

section.cookie-alert .alert {
  margin-bottom: 0;
  margin-top: 10px;
}

section.cookie-alert .alert-success {
  color: #666666;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

section.cookie-alert a {
  display: block;
  margin: 10px 0;
  color: #1C954C;
}

section.cookie-alert a.close {
  margin: 0;
  opacity: .7;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #1C954C;
}

a:hover, a:active {
  color: #00A1D0;
}

h1, h2, h3, h4, h5, h6 {
  color: #1C954C;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #00A1D0;
  border: 1px solid #00A1D0;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.label {
  color: #666;
  display: block;
  padding: .3em .6em .4em .2em;
  text-align: left;
}

main {
  padding: 30px 0;
}

/* blog posts */
.post {
  padding: 20px 0;
  margin-bottom: 5px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.meta {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;
  font-size: 13px;
}

.meta i {
  color: #279B37;
}

.meta.excerpt {
  border: none;
}

.author, .date {
  padding: 0 10px 0px 5px;
}

p.title {
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 10px;
  color: #00A1D0;
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
}

p.title a {
  color: #00A1D0;
}

.post-excerpt {
  padding: 20px 0;
  margin-bottom: 5px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.post-excerpt p.title {
  border: none;
  margin-bottom: 5px;
}

.post-excerpt a.button {
  font-weight: bold;
}

.post-excerpt a.button {
  text-transform: uppercase;
  color: #1C954C;
  border-bottom: 1px solid #1C954C;
  letter-spacing: .1em;
}

.pagination {
  display: block;
  min-height: 50px;
  border-bottom: 1px solid #f2f2f2;
}

.pagination a {
  text-transform: uppercase;
  color: #00A1D0;
  border-bottom: 1px solid #00A1D0;
  letter-spacing: .1em;
}

.pagination a.right {
  display: inline-block;
  float: right;
}

.pagination a.left {
  display: inline-block;
  float: left;
}

.pagination a:hover {
  text-decoration: none;
  font-weight: bold;
}

/* sidebar */
aside h3.widget-title, aside h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid #f2f2f2;
}

/* footer */
footer {
  background-color: #F4F4F4;
  padding: 20px 0;
  color: #333;
  font-size: 11px;
  line-height: 21px;
}

footer .container {
  margin-left: auto;
  max-width: 100%;
}

footer a {
  color: #333;
  padding-right: 10px;
}

footer .copyright {
  padding-right: 10px;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #00A1D0;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #F4F4F4 !important;
  padding: 25px 0 30px;
  text-align: center;
}

section.page-title-wrap h1.page-title {
  font-size: 30px;
  line-height: 37px;
  color: #1C954C;
  font-weight: 700;
  margin: 0 auto;
  text-align: left;
  max-width: 1170px;
  padding-left: 10px;
}

/*hero only needed when including the hero partial*/
section.hero {
  text-align: center;
  background-color: #00A1D0;
  background-image: url(/images/search.jpg);
  padding: 0;
  min-height: 460px;
  background-size: contain;
  background-position: 18% 30%;
  background-repeat: no-repeat;
}

section.hero .hero_message {
  text-align: right;
  margin: 0 0 0 28%;
  padding: 7% 0;
  width: 60%;
  background: transparent;
}

section.hero .hero_message h1 {
  font-size: 2em;
  line-height: 1.5em;
  font-weight: bold;
  padding-bottom: 10px;
  color: #FFFFFF;
  text-align: left;
}

section.hero .hero_message p {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 27px;
  padding-bottom: 30px;
  text-align: left;
  margin: auto;
}

section.hero .hero_message a.read-more {
  background-color: #19c371;
  border: 1px solid #19c371;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 3px;
  display: inline-block;
}

@media screen and (max-width: 1600px) {
  section.hero {
    min-height: auto;
    background-position: 10% 48%;
  }
}

@media screen and (max-width: 1000px) {
  section.hero {
    min-height: auto;
    background-position: 5% 48%;
    text-align: center;
  }
  section.hero .hero_message h1 {
    font-size: 2em;
    line-height: 1.2em;
    padding-bottom: 10px;
    margin-top: 0;
  }
}

@media screen and (max-width: 900px) {
  section.hero {
    background-image: none;
  }
  section.hero .hero_message {
    width: 100%;
    margin: auto;
  }
  section.hero .hero_message h1 {
    font-size: 1.8em;
  }
  section.hero .hero_message p {
    font-size: 15px;
  }
}

/*home search only needed when including the search-jobs partial*/
section#home-search {
  text-align: center;
  background-color: #F2f2f2;
  padding: 20px 0 0;
}

section#home-search .column {
  max-width: 1170px;
  text-align: center;
  margin: auto;
  padding-bottom: 40px;
}

section#home-search h3 {
  color: #1C954C;
  font-size: 30px;
  line-height: 57px;
  font-family: 'Lato', sans-serif;
}

section#home-search input[type="text"] {
  width: 38%;
  margin: 0 10px 20px 0;
}

section#home-search input[type="submit"] {
  display: inline-block;
  height: 44px;
  width: 20%;
}

@media screen and (max-width: 660px) {
  section#home-search input[type="text"] {
    width: 90%;
    margin: 0 0 20px 0;
  }
  section#home-search input[type="submit"] {
    width: 90%;
    display: block;
  }
}

/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
}

aside ul.sectors li, aside ul.locations li {
  flex-basis: 100%;
}

aside ul.sectors li a, aside ul.locations li a {
  color: #666666;
}

aside ul.sectors li i, aside ul.locations li i {
  color: #279B37;
  width: 25px;
  padding-right: 10px;
}

/* job listings */
h3.jobs-section {
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}

.jobs {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.jobs section {
  padding: 30px 15px;
  border-top: 1px solid #f2f2f2;
}

.jobs h2.Title {
  margin-bottom: 5px;
}

.jobs h2.Title a {
  color: #00A1D0;
}

.jobs .TimePosted {
  display: none;
}

.jobs .snippet {
  display: block;
}

.jobs a.More {
  display: block;
  border-bottom: 2px solid #1C954C;
  line-height: 18px;
  padding-bottom: 7px;
  width: 90px;
  text-align: center;
  margin-top: 10px;
  font-weight: 700;
  color: #1C954C;
}
