$primary-color: #00A1D0;
$secondary-color: #1C954C;
$featured-image: url(/images/search.jpg);
$icon-color:#279B37;

$header-background:white;
$cookie-background:#f2f2f2;

$page-header-bkg:#F4F4F4;
$page-header:$secondary-color;

$hero-bkg:#00A1D0;
$hero-message-bkg:transparent;
$hero-h1-color:#FFFFFF;
$hero-p-color:#FFFFFF;
$hero-button-bkg:#19c371;
$hero-button-text:white;

$toggle-icon-bar:white;
$line-color:#f2f2f2;

$home-search-bkg:#F2f2f2;


/* header and navbar */
header{
  background-color: $header-background;
  width:100%;
  z-index: 100000;
}

.navbar{
  margin-bottom:0;
  min-height:70px;
}
.navbar-brand {
  padding: 15px 15px 15px 0;
  img {
    max-width:200px;
  }
}

.navbar-nav>li>a {
  padding-top:20px;
  color: $primary-color;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
}
.navbar-toggle {
  background-color:$primary-color;
  margin-right:0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}
/*cookie-alert*/
section.cookie-alert {
  background-color: $cookie-background;
}
section.cookie-alert .alert{
  margin-bottom:0;
  margin-top:10px;
}
section.cookie-alert .alert-success {
  color: #666666;
  background-color:  $cookie-background;
  border-color:  $cookie-background;
}
section.cookie-alert a {
  display:block;
  margin:10px 0;
  color:$secondary-color;
}
section.cookie-alert a.close {
  margin:0;
  opacity: .7;
}
/* body, main and basic styles */
body{
  color:#666;
  font:16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$secondary-color;
}
a:hover, a:active{
  color:$primary-color;
}
h1, h2, h3, h4, h5, h6{
  color : $secondary-color;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.label{
  color:#666;
  display: block;
  padding: .3em .6em .4em .2em;
  text-align: left;
}
main {
  padding:30px 0;
}
/* blog posts */
.post {
  padding:20px 0;
  margin-bottom: 5px;
  border-top: 1px solid $line-color;
  border-bottom:1px solid $line-color;
}

.meta {
  padding-bottom:10px;
  margin-bottom :20px;
  border-bottom:1px solid $line-color;
  font-size:13px;
  i {
    color:$icon-color;
  }
}
.meta.excerpt{
  border:none;
}
.author, .date {
  padding:0 10px 0px 5px;
}
p.title {
  font-size: 30px;
  line-height: 37px;
  margin-bottom :10px;
  color:$primary-color;
  padding-bottom:10px;
  border-bottom:1px solid $line-color;
  a{
    color:$primary-color;
  }
}
.post-excerpt{
  padding: 20px 0;
  margin-bottom: 5px;
  border-top: 1px solid $line-color;
  border-bottom:1px solid $line-color;
  p.title{
    border:none;
    margin-bottom:5px;
  }
  a.button{
    font-weight: bold;
  }
  a.button{
    text-transform: uppercase;
    color : $secondary-color;
    border-bottom: 1px solid $secondary-color;
    letter-spacing:.1em;
  }
}
.pagination {
  display:block;
  min-height :50px;
  border-bottom: 1px solid $line-color;

  a {
    text-transform: uppercase;
    color : $primary-color;
    border-bottom: 1px solid $primary-color;
    letter-spacing:.1em;
  }
  a.right {
    display:inline-block;
    float:right;
  }
  a.left {
    display:inline-block;
    float:left;
  }
  a:hover{
    text-decoration: none;
    font-weight:bold;
  }
}
/* sidebar */
aside h3.widget-title, aside h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid $line-color;
}
/* footer */
footer{
  background-color:#F4F4F4;
  padding: 20px 0;
  color:#333;
  font-size: 11px;
  line-height: 21px;
  .container{
    margin-left: auto;
    max-width: 100%;
  }
  a {
    color:#333;
    padding-right:10px;
  }
  .copyright {
    padding-right: 10px;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;

  a {
    display:block;
    background-color: $primary-color;
    color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding               : 25px 0 30px;
  text-align            : center;

  h1.page-title {
    font-size: 30px;
    line-height: 37px;
    color: $page-header;
    font-weight: 700;
    margin:0 auto;
    text-align:left;
    max-width:1170px;
    padding-left:10px;
  }
}

/*hero only needed when including the hero partial*/

section.hero {
  text-align: center;
  background-color: $hero-bkg;
  background-image: $featured-image;
  padding: 0;
  min-height: 460px;
  background-size: contain ;
  background-position: 18% 30%;
  background-repeat: no-repeat;
  .hero_message {
    text-align: right;
    margin: 0 0 0 28%;
    padding: 7% 0;
    width: 60%;
    background: $hero-message-bkg;
    h1 {
      font-size: 2em;
      line-height: 1.5em;
      font-weight: bold;
      padding-bottom: 10px;
      color: $hero-h1-color;
      text-align:left;
    }
    p {
      color:$hero-p-color;
      font-size: 16px;
      line-height: 27px;
      padding-bottom :30px;
      text-align: left;
      margin: auto;
    }
    a.read-more {
      background-color: $hero-button-bkg;
      border:1px solid $hero-button-bkg;
      color: $hero-button-text;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 25px;
      border-radius: 3px;
      display:inline-block;
    }

  }
}
@media screen and (max-width:1600px) {
  section.hero {
    min-height: auto;
    background-position: 10% 48%;
  }
}
@media screen and (max-width:1000px) {
  section.hero {
    min-height:auto;
    background-position:5% 48%;
    text-align: center;
    .hero_message {
      h1 {
        font-size: 2em;
        line-height: 1.2em;
        padding-bottom: 10px;
        margin-top:0;
      }
    }
  }
}
@media screen and (max-width:900px){
  section.hero{
    background-image:none;
    .hero_message {
      width:100%;
      margin:auto;
      h1 {
        font-size: 1.8em;
      }
      p{
        font-size:15px;
      }
    }
  }
}
/*home search only needed when including the search-jobs partial*/
section#home-search{
  text-align:center;
  background-color:$home-search-bkg;
  padding: 20px 0 0;
  .column{
    max-width:1170px;
    text-align:center;
    margin:auto;
    padding-bottom:40px;
  }
  h3{
    color: $secondary-color;
    font-size: 30px;
    line-height: 57px;
    font-family: 'Lato', sans-serif;
  }
  input[type="text"]{
    width:38%;
    margin:0 10px 20px 0;
  }
  input[type="submit"]{
    display:inline-block;
    height:44px;
    width:20%;
  }
}
@media screen and (max-width:660px) {
  section#home-search {
    input[type="text"] {
      width: 90%;
      margin: 0 0 20px 0;
    }
    input[type="submit"] {
      width: 90%;
      display: block;
    }
  }
}
/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
}
aside ul.sectors li, aside ul.locations li {
  flex-basis: 100%;
  a{
    color:#666666;
  }
  i{
    color: $icon-color;
    width: 25px;
    padding-right: 10px;
  }
}

/* job listings */
h3.jobs-section{
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}
.jobs {
  border-bottom:1px solid $line-color;
  padding-bottom: 20px;
  margin-bottom:20px;
  section {
    padding: 30px 15px;
    border-top: 1px solid $line-color;
  }
  h2.Title{
    margin-bottom: 5px;
    a{
      color: $primary-color;
    }
  }
  .TimePosted{
    display:none;
  }
  .snippet{
    display: block;
  }
  a.More{
    display: block;
    border-bottom: 2px solid $secondary-color;
    line-height: 18px;
    padding-bottom: 7px;
    width: 90px;
    text-align: center;
    margin-top: 10px;
    font-weight: 700;
    color:$secondary-color;
  }
}